import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"
import "../../css/gallery.scss"
import LightGallery from 'lightgallery/react'
import BuildVideo from '../../videos/HFH-build-video.mp4'

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const onInit = () => {
	console.log('lightGallery has been initialized');
};
const CurrentBuildPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-2.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Current Builds</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<section>
						<h2>Meet our Partner Families</h2>
						<p><strong>Strength</strong>: A Habitat home is a strong foundation for a family, an opportunity on which they can build a better life. Strong and stable homes help build strong and stable communities.</p>
					</section>
					<video className="currentBuildVideo" controls width="100%" height="auto" poster="/current-build/hfh-current-build-video-poster.jpg">
						<source src={BuildVideo} type="video/mp4" />
						<source src={BuildVideo} type="video/ogg" />
					</video>
					<div className="gallery">
						<LightGallery
							onInit={onInit}
							speed={500}
							plugins={[lgThumbnail, lgZoom]}
						>
							<a href="/current-build/thayer-street-1.jpeg">
								<img alt="Current Build" src="/current-build/thayer-street-1.jpeg" />
							</a>
							<a href="/current-build/thayer-street-8.jpeg">
								<img alt="Current Build" src="/current-build/thayer-street-8.jpeg" />
							</a>
							<a href="/current-build/slate3.jpg">
								<img alt="Current Build" src="/current-build/slate3.jpg" />
							</a>
							<a href="/current-build/slate2.jpg">
								<img alt="Current Build" src="/current-build/slate2.jpg" />
							</a>
							<a href="/current-build/slate.jpg">
								<img alt="Current Build" src="/current-build/slate.jpg" />
							</a>
						</LightGallery>
					</div>
					<div className="spacer"></div>
					<section>
						<h2>Build 26 - Rhinelander</h2>
						<div className="gallery">
							<LightGallery
								onInit={onInit}
								speed={500}
								plugins={[lgThumbnail, lgZoom]}
							>
								<a href="/build-26/build-26-1.jpg">
									<img alt="Build 26" src="/build-26/build-26-1.jpg" />
								</a>
								<a href="/build-26/build-26-2.jpg">
									<img alt="Build 26" src="/build-26/build-26-2.jpg" />
								</a>
								<a href="/build-26/build-26-3.jpg">
									<img alt="Build 26" src="/build-26/build-26-3.jpg" />
								</a>
								<a href="/build-26/build-26-4.jpg">
									<img alt="Build 26" src="/build-26/build-26-4.jpg" />
								</a>
							</LightGallery>
						</div>
						<div className="spacer"></div>
						<p><a href="/build-26/Alex-Story.pdf" target="_blank" className="button">Read Alex's Story</a></p>
					</section>
					<div className="spacer"></div>
					<section>
						<h2>Build 27 - St. Germain</h2>
						<div className="gallery">
							<LightGallery
								onInit={onInit}
								speed={500}
								plugins={[lgThumbnail, lgZoom]}
							>
								<a href="/build-27/build-27-1.jpg">
									<img alt="Build 27" src="/build-27/build-27-1.jpg" />
								</a>
								<a href="/build-27/build-27-2.jpg">
									<img alt="Build 27" src="/build-27/build-27-2.jpg" />
								</a>
							</LightGallery>
						</div>
					</section>
					<div className="spacer"></div>
					<section>
						<h2>Build 28 - Rhinelander</h2>
						<div className="gallery">
							<LightGallery
								onInit={onInit}
								speed={500}
								plugins={[lgThumbnail, lgZoom]}
							>
								<a href="/build-28/build-28-1.jpg">
									<img alt="Build 28" src="/build-28/build-28-1.jpg" />
								</a>
								<a href="/build-28/build-28-2.jpg">
									<img alt="Build 28" src="/build-28/build-28-2.jpg" />
								</a>
							</LightGallery>
						</div>
					</section>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default CurrentBuildPage

export const Head = () => (
	<Seo
        title="Current Build | Habitat For Humanity Northwoods Wisconsin"
    />
)